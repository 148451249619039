import React from 'react';
import FontAwesome from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import {
  amberHighlightColor,
  darkGreyBackground,
  pageBackground,
} from './Layout/styles';

export default class ContentWarning extends React.Component {
  render() {
    const light = '#A68100';
    const dark = pageBackground;
    const stripedCss = {
      background: `repeating-linear-gradient(45deg, ${light}, ${light} 10px, ${dark} 10px, ${dark} 20px)`,
    };

    const warningStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',

      color: amberHighlightColor,
      ...stripedCss,
      padding: '5px',
      borderRadius: '10px',
    };

    const iconStyle = {
      background: darkGreyBackground,
      padding: '5px 10px',
    };

    return (
      <h5 css={warningStyle}>
        <FontAwesome
          icon={['far', 'exclamation-triangle']}
          style={{
            ...iconStyle,
            borderBottomLeftRadius: '5px',
            borderTopLeftRadius: '5px',
          }}
          size='lg'
        />
        <Link
          to='/blog/2018-10-15-content-apologies/'
          css={{ padding: '5px 25px', background: darkGreyBackground }}
        >
          I am working on updating this content. Click for more details...
        </Link>
        <FontAwesome
          icon={['far', 'exclamation-triangle']}
          style={{
            ...iconStyle,
            borderBottomRightRadius: '5px',
            borderTopRightRadius: '5px',
          }}
          size='lg'
        />
      </h5>
    );
  }
}
