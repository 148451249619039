import React from 'react';
import Helmet from 'react-helmet';
import ContentWarning from '../components/ContentWarning';
import { articleStyle } from '../components/Layout/styles';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

export default class PageTemplate extends React.Component {
  render() {
    const { frontmatter, html } = this.props.data.markdownRemark;

    const contentWarning =
      frontmatter.rebuild !== 'yes' ? null : <ContentWarning />;

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{frontmatter.title}</title>
          </Helmet>
          <article css={articleStyle}>
            {contentWarning}
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              css={{ width: '80ch', marginLeft: 'auto', marginRight: 'auto' }}
            />
          </article>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        rebuild
      }
    }
  }
`;
